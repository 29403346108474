<template>
  <div class="container-xl" v-if="!$store.getters.isForbidden">
    <div class="card col-lg-12 mt-2">
      <div class="card-header flex justify-content-between">
        <div class="card-title">{{ $route.name }} List</div>
          <div class="flex flex-row mb-2 pr-2 float-right">
            <el-input clearable @clear="searchHandler" style="width: 200px" size="small" type="text" v-model="search_referral.search_value" placeholder="Input search keyword" class="mr-2"/>
            <el-select size="small" v-model="search_referral.search" class="mr-2" style="width: 150px">
              <el-option v-for="(item, index) in search_options" :key="index" :value="item.value" :label="item.text"/>
            </el-select>
            <el-select size="small" v-model="search_referral.sort_by" placeholder="Sort By" class="mr-2" filterable style="width: 100px">
              <el-option
                v-for="item in sortByOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-select size="small" v-model="search_referral.order_by" placeholder="Order By" class="mr-2" filterable style="width: 100px">
              <el-option
                v-for="item in orderByOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-button type="primary" @click="searchHandler" size="small"><font-awesome-icon icon="search"/> Search</el-button>
            <el-button type="primary" size="small" v-b-modal.add>Create Referral Program</el-button>
          </div>
      </div>
      <div class="table-container">
        <div class="table-responsive">
          <table class="table card-table text-nowrap table-vcenter">
            <thead>
              <tr>
                <th width="15%">ID</th>
                <th width="25%">Name</th>
                <th width="25%">Referral Category</th>
                <th width="15%">Referred Amount</th>
                <th width="15%">Referrer Amount</th>
                <th width="15%">Max Claim</th>
                <!-- <th width="20%">Description</th> -->
                <th width="15%">Period</th>
                <th width="15%">Status</th>
                <th width="15%">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in list" :key="i">
                <td class="table-sticky-left">{{ item?.id }}</td>
                <td class="table-sticky-left">{{ item?.name }}</td>
                <td class="table-sticky-left">{{ item.referral_categories?.[0]?.name }}</td>
                <td>{{ item?.referred_amount_str }}</td>
                <td>{{ item?.referrer_amount_str }}</td>
                <td>{{ item?.max_claim }}</td>
                <!-- <td width="20%">{{ item?.description }}</td> -->
                <td>{{ item?.period }}</td>
                <td class="text-nowrap text-muted child2-sticky"><span class="tag" :class="item.status_color">{{ item.status_str }}</span></td>
                <td>
                  <el-button type="primary" @click="editByID(item.id)" size="small">Edit</el-button>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- CREATE REFERRAL PROGRAM -->
          <b-modal size="xl" id="add" title="Create Referral Program" hide-footer v-model="showModalCreateReferralProgram" @hide="closeModalReferralProgram">
            <b-form @submit.prevent="createReferralProgram" @reset="closeModalReferralProgram">
              <b-form-group label="Referral Category ID">
                <el-select class="w-full" v-model="referral.referral_category_id" placeholder="Select Referral Category ID">
                  <el-option v-for="category in listCategory" :key="category.id" :value="category.id" :label="category.name">
                  </el-option>
                </el-select>
                <span class="text-red-600 text-xs" v-if="!referral.referral_category_id">*Value is required</span>
              </b-form-group>
              <b-form-group label="Program Name">
                <el-input placeholder="Input Program Name" v-model="referral.name" />
                <span class="text-red-600 text-xs" v-if="!referral.name">*Value is required</span>
              </b-form-group>
              <b-form-group label="Exclude Company">
                <el-select multiple class="mb-2" :loading="loading.company" v-model="referral.exclude_company" placeholder="Select or search company" style="width: 100%" filterable remote :remote-method="searchCompany">
                  <el-option
                    v-for="item in companies.rows"
                    :key="item.company_id"
                    :label="item.company_name"
                    :value="item.company_id">
                  </el-option>
                </el-select>
              </b-form-group>
              <b-form-group label="Description">
                <el-input placeholder="description" v-model="referral.description" />
                <span class="text-red-600 text-xs" v-if="!referral.description">*Value is required</span>
              </b-form-group>
              <b-form-group label="Referrer Amount">
                <b-input
                  placeholder="Input maximal claim"
                  v-model="referral.referrer_amount"
                  type="number"/>
                <span class="text-red-600 text-xs" v-if="!referral.referrer_amount">*Value is required</span>
              </b-form-group>
              <b-form-group label="Referred Amount">
                <b-input
                  placeholder="Input amount"
                  v-model="referral.referred_amount"
                  type="number"/>
                <span class="text-red-600 text-xs" v-if="!referral.referred_amount">*Value is required</span>
              </b-form-group>
              <b-form-group label="Period">
                <el-select class="w-full" v-model="referral.period" placeholder="Select Period">
                  <el-option v-for="period in period_list" :key="period.value" :value="period.value" :label="period.name">
                  </el-option>
                </el-select>
                <span class="text-red-600 text-xs" v-if="!referral.period">*Value is required</span>
              </b-form-group>
              <b-form-group label="Max Claim">
                <b-input
                  v-model="referral.max_claim"
                  placeholder="Input maximal claim"
                  type="number"/>
              </b-form-group>
              <b-form-group label="Prefix Code Referral">
                <el-input placeholder="Input prefix code referral" v-model="referral.prefix_code_referral"/>
                <span class="text-red-600 text-xs" v-if="!referral.prefix_code_referral">*Value is required</span>
              </b-form-group>
              <b-form-group label="Term and Condition">
                <quill-editor v-model="referral.tnc"
                  ref="tnc"
                  :options="editorOption"
                  style="height: 150px"
                  >
                </quill-editor>
              </b-form-group><br><br><br>
              <b-form-group label="Info Share">
                <b-textarea rows="5" v-model="referral.info_share" placeholder="Input info share code referral"/>
              </b-form-group>
              <div class="d-flex flex-row float-right">
                <el-button @click="createReferralProgram" :disabled="!referral.referral_category_id || !referral.description || !referral.name || !referral.referrer_amount || !referral.referred_amount || !referral.period" class="mr-2 ml-2" size="small" type="primary">Submit Referral</el-button>
                <el-button @click="closeModalReferralProgram" size="small" class="mr-2">Cancel</el-button>
              </div>
            </b-form>
          </b-modal>
          <!-- EDIT -->
          <b-modal size="xl" v-model="showDetailID" :title="'Edit Referral Program #' + editID" hide-footer>
            <b-form @submit.prevent="doUpdateData" @reset="closeModalReferralProgram">
              <b-form-group label="Max Claim">
                <b-input
                  v-model="referral_edit.max_claim"
                  placeholder="Input maximal claim"
                  type="number"/>
              </b-form-group>
              <b-form-group label="Status">
                <el-select class="w-full" v-model="referral_edit.status" placeholder="Select Referral status">
                 <el-option v-for="status in status_options" :key="status.value" :value="status.value" :label="status.text">
                  </el-option>
                </el-select>
              </b-form-group>
               <b-form-group label="Exclude Company">
                <el-select multiple class="mb-2" :loading="loading.company" v-model="referral_edit.exclude_company" placeholder="Select or search company" style="width: 100%" filterable remote :remote-method="searchCompany">
                  <el-option
                    v-for="item in companies.rows"
                    :key="item.company_id"
                    :label="item.company_name"
                    :value="item.company_id">
                  </el-option>
                </el-select>
              </b-form-group>
              <b-form-group label="Term and Condition">
                <quill-editor v-model="referral_edit.tnc"
                  ref="tnc"
                  :options="editorOption"
                  style="height: 150px"
                  >
                </quill-editor><br><br><br>
                <span class="text-red-600 text-xs" v-if="!referral_edit.tnc">*Value is required</span>
              </b-form-group>
              <b-form-group label="Info Share">
                <b-textarea rows="5" v-model="referral_edit.info_share" placeholder="Input info share code referral"/>
                <span class="text-red-600 text-xs" v-if="!referral_edit.info_share">*Value is required</span>
              </b-form-group>
              <div class="d-flex flex-row float-right">
                <el-button @click="doUpdateData" :disabled="!referral_edit.tnc || !referral_edit.info_share" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
                <el-button @click="closeModalEdit" size="small" class="mr-2">Cancel</el-button>
              </div>
            </b-form>
          </b-modal>
          <el-empty description="No data found" v-if="total_rows == 0" :image="emptyStateImage"></el-empty>
        </div>
      </div>
      <pagination :page="page" :per_page="per_page" :list="list" :total_rows="total_rows"
        @pageChange="pageChangeHandler" />
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { upperFirst } from 'lodash';
import emptyStateImage from '@/assets/images/empty-state.png';
import Pagination from '../components/Pagination.vue';
import { GET_COMPANIES } from '@/store/modules/companies';
import { CREATE_REFERRAL_PROGRAM, GET_REFERRAL_PROGRAM, EDIT_REFERRAL_PROGRAM, GET_CATEGORIES } from '@/store/modules/app_user';
import popupErrorMessages from '@/library/popup-error-messages';
import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
export default {
  components: { Pagination, quillEditor },
  name: 'Referral Program',
  metaInfo: {
    title: 'Referral Program',
  },
  data() {
    return {
      loading: {
        submit: false,
        company: false,
        balance: false,
      },
      show: false,
      companies: {
        rows: [],
        count: 0,
      },
      timeoutSearchCompany: null,
      showModalCreateReferralProgram: false,
      editID: null,
      showDetailID: false,
      company_id: 6667,
      referral: {
        referral_category_id: '',
        name: '',
        description: '',
        referrer_amount: 0,
        referred_amount: 0,
        user_id: null,
        max_claim: 0,
        period: '',
        prefix_code_referral: '',
        exclude_company: [],
        tnc: '',
        info_share: ''
      },
      editorOption: {
        placeholder: '1. Referral Ewa adalah program ...',
      },
      referral_edit: {
        status: 0,
        max_claim: 0,
        user_id: null,
        exclude_company: [],
        tnc: '',
        info_share: ''
      },
      period_list: [
        { name: 'month', value: 'month' },
        // { name: 'week', value: 'week' },
        // { name: 'daily', value: 'daily' },
        // { name: 'year', value: 'year' },
      ],
      category: {
        name: '',
        user_id: null,
      },
      user_id: null,
      showSearch: true,
      emptyStateImage,
      per_page: 10,
      size: 10,
      total_rows: 0,
      total_rows_categories: 0,
      page: 1,
      list: [],
      listCategory: [],
      data_ref: [],
      isLoading: true,
      loading_detail: false,
      loader: null,
      sort_by: 'desc',
      order_by: 'id',
      search_referral: {
        sort_by: '',
        order_by: '',
        search: 'name',
        search_value: '',
      },
      sortByOptions: [
        {
          value: 'asc',
          label: 'Asc',
        },
        {
          value: 'desc',
          label: 'Desc',
        },
      ],
       orderByOptions: [
        {
          value: 'id',
          label: 'ID',
        },
        {
          value: 'name',
          label: 'Name',
        },
      ],
      search_value:'',
      search: '',
      search_options: [
        { value: 'name', text: 'Name' },
      ],
      status_options: [
        { value: 1, text: 'Active' },
        { value: 0, text: 'Inactive' },
        { value: 2, text: 'Maintenance' },
        { value: 3, text: 'Coming Soon' },
      ],
      datePickerOptions: {
        disabledDate: (date) => moment(date).isBefore(moment(), 'days'),
      },
    };
  },
  async created() {
    // REFERRAL PROGRAM
    this.referral.user_id = localStorage.getItem('user_id');
    console.log('User ID bener dari referral:', this.referral.user_id);
    // CATEGORY
    this.category.user_id = localStorage.getItem('user_id');
    console.log('User ID bener dari category:', this.category.user_id);
  },
  async mounted() {
    const loader = this.$loading.show();
      this.page = 1;
      await this.getList();
      await this.getListCategory();
      await this.loadCompanies();
    loader.hide();
  },
  computed: {
    showingFrom() {
      return this.total_rows ? ((this.page - 1) * this.per_page) + 1 : 0;
    },
    showingUntil() {
      if (this.total_rows) {
        if (this.list.length < this.per_page) {
          return this.total_rows;
        }
        return this.page * this.per_page;
      }
      return 0;
    },
    formattedNew: {
      get() {
        return this.formatNumber(this.referral.referred_amount);
      },
      set(value) {
        this.referral.referred_amount = this.parseFormattedNumber(value);
      },
    },
    formattedValue: {
      get() {
        return this.formatNumber(this.referral.referrer_amount);
      },
      set(value) {
        this.referral.referrer_amount = this.parseFormattedNumber(value);
      },
    },
    formattedDataLog() {
      return this.log_dipay.map(item => ({
        vendor_id: item.data.vendor_id,
      }));
    },
  },
  methods: {
    pageChangeHandler(page) {
      this.page = page;
      this.getList();
    },
    searchHandler() {
      this.search_referral.sort_by = this.search_referral.sort_by;
      this.search_referral.order_by = this.search_referral.order_by;
      this.search_referral.search = this.search_referral.search;
      this.search_referral.search_value = this.search_referral.search_value; 
      this.getList();
    },
    // ref
    formatNumber(value) {
      if (typeof value !== 'string') {
        value = String(value);
      }
      value = value.replace(/[^0-9.]/g, '');
      const parts = value.split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return parts.join('.');
    },
    parseFormattedNumber(value) {
      return value.replace(/,/g, '');
    },
    // REFERRAL FIX
    async createReferralProgram() {
      this.referral.referral_category_id = parseInt(this.referral.referral_category_id);
      this.referral.referrer_amount = parseInt(this.referral.referrer_amount);
      this.referral.referred_amount = parseInt(this.referral.referred_amount);
      this.referral.max_claim = parseInt(this.referral.max_claim);
      try {
        const requestBody = {
          referral_category_id: this.referral.referral_category_id, 
          name: this.referral.name,
          exclude_company: this.referral.exclude_company,
          description: this.referral.description,
          referrer_amount: this.referral.referrer_amount,
          referred_amount: this.referral.referred_amount,
          user_id: this.referral.user_id,
          max_claim:  this.referral.max_claim,
          period:  this.referral.period,
          prefix_code_referral: this.referral.prefix_code_referral,
          tnc:  this.referral.tnc,
          info_share:  this.referral.info_share,
        };
        const response = await this.$store.dispatch(CREATE_REFERRAL_PROGRAM, { data: requestBody });
        this.referral.referral_category_id = '';
        this.referral.name = '';
        this.referral.exlude_company = '';
        this.referral.description = '',
        this.referral.referred_amount = '',
        this.referral.user_id = '',
        this.referral.max_claim = '',
        this.referral.period = '',
        this.referral.prefix_code_referral = '',
        this.referral.info_share = '',
        this.referral.tnc = '',
        this.data_ref = response;
        this.$message({
            title: 'Success',
            type: 'success',
            message: 'Referral Program created',
        });
        this.closeModalReferralProgram();
        this.getList();

      } catch (err) {
        popupErrorMessages(err.response.data);
      }
    },
    // del
    confirmCreateReferralProgram() {
      if (this.modalType === 'add') {
        this.doCreateReferralProgram();
        return;
      }
      this.$confirm('You will create a new Referral Program. Make sure you have input data correctly. Continue?', 'Confirmation', {
        type: 'info',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            this.doCreateReferralProgram();
          }
          done();
        },
      }).catch(() => {});
    },
    // CREATE_REFERRAL_PROGRAM
    async doCreateReferralProgram() {
      this.referral.referral_category_id = parseInt(this.referral.referral_category_id);
      this.referral.referrer_amount = parseInt(this.referral.referrer_amount);
      this.referral.referred_amount = parseInt(this.referral.referred_amount);
      this.referral.max_claim = parseInt(this.referral.max_claim);
      console.log("REFERALLLL", this.referral);
      await this.$store.dispatch(CREATE_REFERRAL_PROGRAM, this.referral)
        .then(() => {
          this.$message({
            title: 'Success',
            type: 'success',
            message: 'Referral Program created',
          });
          // this.closeModalReferralProgram();
          this.getList();
        }).catch((err) => {
          popupErrorMessages(err.response.data);
        });
    },
    closeModalReferralProgram() {
      this.showModalCreateReferralProgram = false,
      this.referral = {
        referral_category_id: '',
        name: '',
        exclude_company: [],
        description: '',
        referred_amount: '',
        user_id: '',
        max_claim: '',
        period: '',
        prefix_code_referral: '',
        tnc: '',
        info_share: '',
      };
    },
    closeModalEdit() {
      this.showDetailID = false;
    },
    async getList() {
      await this.$store.dispatch(GET_REFERRAL_PROGRAM, {
        page: this.page,
        // per_page: this.per_page,
        sort_by: this.search_referral.sort_by,
        order_by: this.search_referral.order_by,
        search: this.search_referral.search,
        search_value: this.search_referral.search_value
      }).catch(() => {});
      const { rows, count } = this.$store.getters.get_referral_program;
      this.total_rows = count || 0;
      this.list = rows;
    },
    async getListCategory() {
      await this.$store.dispatch(GET_CATEGORIES, this.filter).catch(() => {});
      const { rows, count } = this.$store.getters.get_categories;
      this.total_rows_categories = count || 0;
      this.listCategory = rows;
      console.log('listCategory:', this.listCategory)
    },
    editByID(id) {
      this.editID = id;
      this.showDetailID = true;
      const selectedItem = this.list.find(item => item.id === id);
      this.referral_edit.user_id = selectedItem ? selectedItem.user_id : null;
      this.referral_edit.max_claim = selectedItem ? selectedItem.max_claim : 0;
      this.referral_edit.status = selectedItem ? selectedItem.status : '';
      this.referral_edit.exclude_company = selectedItem ? selectedItem.exclude_company : [],
      this.referral_edit.tnc = selectedItem ? selectedItem.tnc : '';
      this.referral_edit.info_share = selectedItem ? selectedItem.info_share : '';
    },
    async doUpdateData(id) {
      this.referral_edit.max_claim = parseInt(this.referral_edit.max_claim);
      this.referral_edit.status = parseInt(this.referral_edit.status);
      this.$confirm('You will update this program referral. Make sure you have input data correctly. Continue?', 'Confirmation', {
        type: 'info',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            done();
            const payload = {
              id: this.editID,
              data: this.referral_edit
            };
            await this.$store.dispatch(EDIT_REFERRAL_PROGRAM, payload)
              .then(() => {
                this.$message({
                  title: 'Success',
                  type: 'success',
                  message: 'Program referral updated',
                });
                this.showDetailID = false;
                this.getList();
              }).catch((err) => {
                popupErrorMessages(err.response.data);
              });
            return;
          }
          done();
        },
      }).catch(() => {});
    },
    // NEW METHOD
    async loadCompanies({ search_keyword = '' } = {}) {
      this.loading.company = true;
      await this.$store.dispatch(GET_COMPANIES, {
        search_keyword,
      });
      this.loading.company = false;
      this.companies = this.$store.getters.companies;
    },
    searchCompany(value) {
        clearTimeout(this.timeoutSearchCompany);
        this.timeoutSearchCompany = setTimeout(() => {
          this.loadCompanies({
            search_keyword: value,
          });
        }, 1000);
    },
  },
  // NEW
  watch: {
    isShown() {
      this.show = this.isShown;
      this.v$.form.$touch();
      if (this.show) {
        this.loadCompanies();
      }
    },
  },
  props: {
    isShown: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style>
.el-range-separator {
  width: 10% !important;
}

.child1-sticky {
  position: sticky;
  right: 0px;
  width: 40px;
  background: #fff;
}

.child2-sticky {
  position: sticky;
  right: 69px;
  width: 255px;
  background: #fff;
}
/* del */
.table-container {
  width: 100%;
  overflow-x: auto;
}

.table-responsive {
  overflow-x: auto;
}

.buttonload {
  background-color: #006EA5; /* Green background */
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 12px 16px; /* Some padding */
  font-size: 16px /* Set a font size */
}

.dialog-footer button:first-child {
  margin-right: 10px;
}
</style>